import { useSelector } from "react-redux";
import { whatsAppBt, facebookBt } from "../../assets/util/forms.util.js";
import { Container } from "react-bootstrap";
import SEO from "../../components/common/SEO.js";
import NavBar from "../../components/common/NavBar.js";
import About from "../../components/common/About.js";
import Post from "./Post.js";
import { useEffect } from "react";
import siteScript from "./script.js";
import Contact from "../../components/common/Contact.js";
import Footer from "../../components/common/Footer.js";

import styles from "./site.module.css";
import SocialBts from "../../components/common/SocialBts.js";
import Products from "../../components/common/Products.js";

const Loretta3Home = () => {
  useEffect(() => {
    siteScript();
    // document.body.classList.add(styles.lorettaBody);
    // return () => {
    //   document.body.classList.remove(styles.lorettaBody);
    // };
  }, []);

  const { name, seo, nav, socialLinks, post, about, company, products } =
    useSelector((state) => state.loretta);

  return (
    <Container id="loretta-home" className={styles.lorettaHome}>
      Loretta3
      <SEO seo={seo} />
      <NavBar nav={nav} />
      <Post post={post} />
      <About about={about} style={styles.aboutUs} />
      <Products about={products} style={styles.aboutUs} />
      <Contact />
      <Footer
        nav={nav}
        socialLinks={socialLinks.footer}
        name={name}
        style={styles}
      />
      {/* {whatsAppBt(company)} */}
      {/* {facebookBt(company)} */}
      <SocialBts socialLinks={socialLinks.float} />
    </Container>
  );
};

export default Loretta3Home;
