import instagram from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import mail from "../../assets/images/mail.png";
import "./contact.css";

const Contact = () => {
  const handleClick = () => {};

  return (
    <section id="contact" aria-labelledby="contact-title">
      <div className="contact-row reveal">
        {/* <!-- Columna del Formulario de Contacto --> */}
        <div className="contact-col">
          <h2 id="contact-title">Contáctenos</h2>
          <form className="contact-form" action="/submit-form" method="POST">
            <div class="form-group">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Tu nombre"
                required
                aria-required="true"
              />
            </div>

            <div class="form-group">
              <label htmlFor="email">Correo Electrónico</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Tu correo electrónico"
                required
                aria-required="true"
              />
            </div>

            <div class="form-group">
              <label htmlFor="message">Mensaje</label>
              <textarea
                id="message"
                name="message"
                placeholder="Escribe tu mensaje aquí"
                required
                aria-required="true"
              ></textarea>
            </div>

            <button onClick={handleClick}>Enviar Mensaje</button>
          </form>
        </div>

        {/* <!-- Columna de Íconos Sociales --> */}
        {/* <div className="contact-col">
          <div
            className="social-icon-wrapper"
            aria-label="Redes Sociales de Loretta3"
          >
            <a
              href="https://www.instagram.com/loretta3_it"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram de Loretta3"
            >
              <img src={instagram} alt="Instagram Loretta3" />
            </a>
            <a
              href="https://www.facebook.com/loretta3.it"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook de Loretta3"
            >
              <img src={facebook} alt="Facebook Loretta3" />
            </a>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Contact;
