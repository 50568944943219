import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";

export const fetch = createAsyncThunk(
  `${siteConstants.CUST_ANCESTRAL}/fecth`,
  async (payload) => {
    return [];
  }
);

const initialState = {
  id: siteConstants.CUST_ANCESTRAL,
  name: "Ancestral Café Galería",
  author: "Ancestral Café Galería",
  type: "Cafe Galeria",
  seo: {
    title: "Ancestral Café Galería - Café, Postres y Arte en Zipaquirá",
    description:
      "Visita Ancestral Café Galería en el centro histórico de Zipaquirá y disfruta de nuestros postres, café, libros y arte en un ambiente único.",
    keywords:
      "Ancestral Café Galería, Café en Zipaquirá, Postres en Zipaquirá, Arte en Zipaquirá, Cafetería en el centro histórico de Zipaquirá,Galería de arte Zipaquirá,Libros y café en Zipaquirá,Ambiente único Zipaquirá,Cafeterías recomendadas en Zipaquirá,Café artesanal Zipaquirá,Planes culturales en Zipaquirá,Postres artesanales en Zipaquirá,Café y arte Colombia,Cafeterías con arte en Colombia,Mejor café en Zipaquirá",
  },
  nav: {
    home: { href: "post-section", label: "Ancestral Café Galería" },
    about: { href: "menu", label: "Menu" },
    // about: { href: "about-us", label: "Menu" },
    // products: { href: "products", label: "Servicios" },
    // contact: { href: "contact", label: "Contacto" },
  },
  post: {
    post: "ANCESTRAL",
    postOne: "Café",
    postTwo: "Galería",
  },
  slogan: {
    one: "Ancestral",
    two: "Sabores que cuentan historias, arte que inspira",
  },
  socialLinks: {
    float: {
      whatsapp:
        "https://api.whatsapp.com/send?phone=3042433341&text=Hola%21%20Quiero%20m%C3%A1s%20informaci%C3%B3n",
    },
    footer: {
      facebook: "https://www.facebook.com/ancestral.zipaquira",
      instagram: "https://www.instagram.com/ancestral.zipaquira",
      linkedin: "",
      twitter: "",
      youtube: "",
      tiktok: "",
      "map-location-dot": "https://maps.app.goo.gl/1YE92oBd5HkVtRFq7",
    },
  },
  image: "https://loretta3.com/logo.png",
  robots: "index, follow", // Controla la indexación y el seguimiento de enlaces
  author: "Loretta3 Soluciones IT", // Autor o nombre de la empresa
  theme: {
    primaryColor: "#001c30",
    secondaryColor: "#230043",
    backgroundColor: "#e3e3d0",
    textColor: "#212529",
  },
  company: {
    name: "Ancestral Café Galería",
    whatsapp: "573227100218",
    address: "Carrera 6 #2-49, Zipaquirá",
  },
  menu: {
    "Bebidas Calientes": [
      { nombre: "Café Americano", precio: { COP: 3000, USD: 1 } },
      { nombre: "Café con Leche", precio: { COP: 5000, USD: 2 } },
      { nombre: "Café Capuchino", precio: { COP: 6000, USD: 2 } },
      { nombre: "Milo", precio: { COP: 4000, USD: 1 } },
      { nombre: "Chocolate", precio: { COP: 5000, USD: 2 } },
      { nombre: "Aromáticas de Frutas", precio: { COP: 4000, USD: 1 } },
      { nombre: "Aromática de Infusión", precio: { COP: 4000, USD: 1 } },
      { nombre: "Té Chai", precio: { COP: 7000, USD: 2 } },
      { nombre: "Agua de Panela", precio: { COP: 4000, USD: 1 } },
      { nombre: "Canelazo/Carajillo", precio: { COP: 7000, USD: 2 } },
    ],
    Postres: [
      {
        nombre: "Maracuyá, Oreo, Limón, Lulo, Chocolate",
        precio: { COP: 9000, USD: 2 },
      },
      { nombre: "Cuajada con un Dulce", precio: { COP: 9000, USD: 2 } },
      { nombre: "Postre de Gelatina", precio: { COP: 6000, USD: 2 } },
      { nombre: "Gelatina", precio: { COP: 6000, USD: 2 } },
      { nombre: "Brownie con Helado", precio: { COP: 10000, USD: 3 } },
      { nombre: "Waffles con Frutas", precio: { COP: 12000, USD: 3 } },
      { nombre: "Brevas con Queso y Arequipe", precio: { COP: 9000, USD: 2 } },
      { nombre: "Obleas", precio: { COP: 9000, USD: 2 } },
      { nombre: "Merengón", precio: { COP: 10000, USD: 3 } },
      { nombre: "Fresas con Crema", precio: { COP: 15000, USD: 4 } },
      { nombre: "Adición de Helado", precio: { COP: 3000, USD: 1 } },
    ],
    Meriendas: [
      { nombre: "Torta de Milkyway", precio: { COP: 6000, USD: 2 } },
      { nombre: "Torta de Limón y Arándanos", precio: { COP: 6000, USD: 2 } },
      { nombre: "Torta de Naranja y Amapola", precio: { COP: 6000, USD: 2 } },
      { nombre: "Mantecada", precio: { COP: 2000, USD: 1 } },
      { nombre: "Pastel de Pollo", precio: { COP: 5000, USD: 2 } },
      { nombre: "Almojábanas", precio: { COP: 2000, USD: 1 } },
      {
        nombre: "Mígao (Chocolate y Amasijos)",
        precio: { COP: 15000, USD: 4 },
      },
      { nombre: "Agua de Panela con Queso", precio: { COP: 7000, USD: 2 } },
      {
        nombre: "Agua de Panela con Queso y Almojabana",
        precio: { COP: 10000, USD: 3 },
      },
    ],
    "Jugos Naturales y Bebidas Frías": [
      {
        nombre:
          "Jugos Naturales (Mango, Maracuyá, Mora, Lulo, Guanabana) en Agua",
        precio: { COP: 6000, USD: 2 },
      },
      { nombre: "Jugos Naturales en Leche", precio: { COP: 8000, USD: 2 } },
      { nombre: "Gatorade", precio: { COP: 5000, USD: 2 } },
      { nombre: "Agua 600 ml", precio: { COP: 2000, USD: 1 } },
      { nombre: "Té y Jugos Hit", precio: { COP: 4000, USD: 1 } },
      { nombre: "Coronita", precio: { COP: 6000, USD: 2 } },
      { nombre: "Stella", precio: { COP: 10000, USD: 3 } },
    ],
  },

  message: null,
  loading: false,
  error: null,
};

const siteSlice = createSlice({
  name: "site",
  initialState: initialState,
  reducers: {
    init(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      });
  },
});

export const { init } = siteSlice.actions;
export default siteSlice.reducer;
