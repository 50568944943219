import "./products.css";

const Products = ({ logo }) => {
  return (
    <section id="our-products">
      <div className="products-grid-wrapper reveal">
        <div className="products-grid-container">
          <div className="grid-item">
            <img src={logo} alt="" />
            <p>Transformación Digital</p>
          </div>
          <div className="grid-item">
            <img src={logo} alt="" />
            <p>Software a la medida</p>
          </div>
          <div className="grid-item">
            <img src={logo} alt="" />
            <p>Consultoría</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
