import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";

export const fetch = createAsyncThunk(
  `${siteConstants.CUST_ARMADILLO}/fecth`,
  async (payload) => {
    return [];
  }
);

// src/reducers/rootReducer.js
const initialState = {
  name: "Artesanías Armadillo",

  id: "artesanias-armadillo",
  name: "Artesanías Armadillo",
  type: "Artesanias",
  title: "Correaje y Cinturones de Cuero Hechos a Mano",
  seo: {
    title:
      "Artesanías Armadillo - Cinturones y Correajes de Cuero Artesanales Hechos a Mano",
    description:
      "Compra correas y cinturones de cuero personalizados, hechos a mano con materiales de alta calidad. Durabilidad y estilo únicos en Artesanías Armadillo.",
    keywords:
      "correas de cuero, cinturones personalizados, artesanía en cuero, productos artesanales Bogotá, cinturones de cuero hechos a mano, artículos de cuero Bogotá, correas de cuero personalizadas, cuero legítimo, cinturones de cuero Cundinamarca, artesanías colombianas, productos hechos a mano Bogotá, accesorios de cuero, correas de cuero Bogotá, cinturones de cuero Cundinamarca, artesanía local Bogotá, regalos personalizados cuero",
    url: "https://loretta3.com/landing/artesanias-armadillo",
    image: "https://loretta3.com/images/armadillo.png",
    robots: "index, follow",
    author: "Loretta3",
  },

  nav: {
    home: { href: "post-section", label: "Artesanias Armadillo" },
    about: { href: "about-us", label: "Productos" },
    // products: { href: "products", label: "Servicios" },
    // contact: { href: "contact", label: "Contacto" },
  },
  post: {
    post: "Armadillo",
    postOne: "Arte",
    postTwo: "sanias",
  },
  about: {
    "Nuestra Historia": {
      desc: "Artesanías Armadillo nace con el propósito de preservar y celebrar la tradición de la marroquinería artesanal. Nos especializamos en la creación de correas y cinturones de cuero hechos a mano, fusionando técnicas ancestrales con diseños contemporáneos. Cada pieza que fabricamos lleva consigo una historia, el esfuerzo de artesanos comprometidos con la calidad y el detalle. Buscamos ofrecer lo mejor de nuestra cultura a través de productos únicos y auténticos.",
    },
    "Nuestra Misión": {
      desc: "En Artesanías Armadillo, nos dedicamos a ofrecer productos excepcionales, fabricados con materiales de la más alta calidad. Usamos cuero legítimo, garantizando resistencia, durabilidad y estética. Cada cinturón y correa es confeccionado a mano con esmero para brindar a nuestros clientes no solo un accesorio funcional, sino también una pieza de arte que perdure en el tiempo. Queremos que cada cliente sienta el valor y el amor que ponemos en cada una de nuestras creaciones.",
    },
    "Nuestra Promesa": {
      desc: "Trabajamos de manera ética y responsable, utilizando materiales sostenibles y promoviendo prácticas de comercio justo. Personalizamos cada producto según las necesidades y gustos de nuestros clientes, ofreciendo artículos de cuero robustos, funcionales y exclusivos. En Artesanías Armadillo, creemos que cada pieza debe ser única y ajustarse perfectamente al estilo personal de quien la lleve. Si buscas un accesorio de cuero que combine tradición, calidad y diseño, tenemos lo que necesitas.",
    },
  },

  products: [
    {
      id: 1,
      name: "Correas para Perros Tejidas y de Cuero",
      description:
        "Correas resistentes y duraderas, hechas a mano combinando cuero legítimo y tejido artesanal. Personalizables según el tamaño y estilo de tu mascota.",
      image: "correas-perros.jpg",
    },
    {
      id: 2,
      name: "Cinturones Personalizados de Cuero y Tejido",
      description:
        "Cinturones únicos, combinando cuero y tejido de alta calidad. Personalízalos en colores y diseños a tu gusto, adaptados a tu estilo.",
      image: "cinturones-cuero-tejido.jpg",
    },
    {
      id: 3,
      name: "Accesorios Artesanales de Cuero y Tejido",
      description:
        "Incluye monederos, billeteras, portatarjetas, llaveros y otros pequeños accesorios hechos con una combinación de cuero y tejido. Diseños prácticos y elegantes para el día a día.",
      image: "accesorios-cuero-tejido.jpg",
    },
    {
      id: 4,
      name: "Correas para Mochilas y Bolsos Tejidas con Cuero",
      description:
        "Correas robustas, ideales para mochilas y bolsos, confeccionadas a mano con tejido de alta resistencia y detalles en cuero para mayor durabilidad.",
      image: "correas-mochilas-bolsos.jpg",
    },
    {
      id: 5,
      name: "Correas para Cámaras Combinadas de Cuero y Tejido",
      description:
        "Correas personalizadas para cámaras, combinando cuero y tejido en un diseño cómodo y duradero, ajustables y resistentes para fotógrafos y viajeros.",
      image: "correas-camaras.jpg",
    },
    {
      id: 6,
      name: "Bolsos Tejidos con Detalles en Cuero",
      description:
        "Bolsos artesanales con una estructura de tejido y detalles en cuero de alta calidad, funcionales y con un diseño único que combina lo moderno y lo tradicional.",
      image: "bolsos-cuero-tejido.jpg",
    },
    {
      id: 7,
      name: "Carteras Tejidas y de Cuero",
      description:
        "Carteras diseñadas con tejido de alta resistencia y detalles en cuero, elegantes y prácticas para el uso diario.",
      image: "carteras-cuero-tejido.jpg",
    },
    {
      id: 8,
      name: "Llaveros Tejidos y de Cuero",
      description:
        "Llaveros pequeños y funcionales, combinando tejido y cuero, ideales para regalar o usar personalmente, con un diseño artesanal y personalizado.",
      image: "llaveros-cuero-tejido.jpg",
    },
    {
      id: 9,
      name: "Accesorios Personalizados para Mascotas (Correas y Collares)",
      description:
        "Correas y collares personalizados para mascotas, hechos a mano con tejido de alta calidad y cuero, ideales para perros y otros animales, disponibles en diferentes tamaños.",
      image: "accesorios-mascotas.jpg",
    },
    {
      id: 10,
      name: "Billeteras Tejidas y de Cuero",
      description:
        "Billeteras funcionales y elegantes, combinando cuero y tejido de alta calidad, con múltiples compartimientos y un diseño único.",
      image: "billeteras-cuero-tejido.jpg",
    },
  ],

  theme: {
    primaryColor: "#001c30",
    secondaryColor: "#230043",
    backgroundColor: "#e3e3d0",
    textColor: "#212529",
  },
  company: {
    name: "Artesanias Armadillo",
    whatsapp: "573011911727",
  },
  socialLinks: {
    float: {
      whatsapp:
        "https://api.whatsapp.com/send?phone=573011911727&text=Hola%21%20Quiero%20m%C3%A1s%20informaci%C3%B3n",
    },
    footer: {
      facebook: "https://www.facebook.com/artesaniasarmadillozipa",
      instagram: "https://www.instagram.com/artesaniasarmadillo/",
      "location-dot": "https://maps.app.goo.gl/VJEKyVFbEd5UyhjG8",
    },
  },
  // themeId: "theme_loretta3",
  // companyId: "company_loretta3",
  // socialLinksId: "social_loretta3",
  // catalogId: "catalog_loretta3",

  message: null,
  loading: false,
  error: null,
  message: null,
  loading: false,
  error: null,
};

const armadilloSlice = createSlice({
  name: siteConstants.CUST_ARMADILLO,
  initialState: initialState,
  reducers: {
    init(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      });
  },
});

export const { init } = armadilloSlice.actions;
export default armadilloSlice.reducer;
