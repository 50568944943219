import "./about.css";

const About = ({ about, style }) => {
  return (
    <section id="about-us" className={style}>
      <meta
        name="description"
        content="Loretta3: Impulsamos el crecimiento empresarial con soluciones IT personalizadas. Ofrecemos desarrollo de software a la medida, presencia digital y consultoría estratégica."
      />
      <div className="about-us-container">
        <div className="about-us-row reveal">
          {about &&
            Object.keys(about).map((item) => (
              <div className="about-us-col" key={`div-${item}`}>
                <article className="about-content" key={item}>
                  <h3>{item}</h3>
                  <p>{about[item].desc}</p>

                  {/* <div id="nav-links-sub">
                    <ul>
                      {about[item] &&
                        about[item].features.map((item, i) => (
                          <li key={i}>{item}</li>
                        ))}
                    </ul>
                  </div> */}

                  {/* <span>
                    desde <strong>{about[item].price}</strong>
                  </span> */}
                </article>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default About;
